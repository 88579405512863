export const GET_SIDENAV_MENU_LIST = 'GET_SIDENAV_MENU_LIST';
export const GET_SIDENAV_MENU_LIST_SUCCESS = 'GET_SIDENAV_MENU_LIST_SUCCESS';
export const SET_EDIT_ROLE_STATE = 'SET_EDIT_ROLE_STATE';
export const SET_ROLE_DATA = 'SET_ROLE_DATA';
// Login Action Type or constants
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';
// Forgot Password constants
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
// Change Password

export const BULK_UPLOAD = 'BULK_UPLOAD';
export const BULK_UPLOAD_SUCCESS = 'BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_FAILURE = 'BULK_UPLOAD_FAILURE';

export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';

export const IMPORT_USERS = 'IMPORT_USERS';
export const IMPORT_USERS_SUCCESS = 'IMPORT_USERS_SUCCESS';
export const IMPORT_USERS_FAILURE = 'IMPORT_USERS_FAILURE';

export const UPLOAD_BLAS = 'UPLOAD_BLAS';
export const UPLOAD_BLAS_SUCCESS = 'UPLOAD_BLAS_SUCCESS';

export const EXPORT_BLAS = 'EXPORT_BLAS';
export const EXPORT_BLAS_SUCCESS = 'EXPORT_BLAS_SUCCESS';
export const EXPORT_BLAS_FAILURE = 'EXPORT_BLAS_FAILURE';

export const EXPORT_GROUPS = 'EXPORT_GROUPS';
export const EXPORT_GROUPS_SUCCESS = 'EXPORT_GROUPS_SUCCESS';
export const EXPORT_GROUPS_FAILURE = 'EXPORT_GROUPS_FAILURE';
export const UPLOAD_TAGS = 'UPLOAD_TAGS';
export const UPLOAD_TAGS_SUCCESS = 'UPLOAD_TAGS_SUCCESS';
export const UPLOAD_TAGS_FAILURE = 'UPLOAD_TAGS_FAILURE';

export const ADD_DEVICE = 'ADD_DEVICE';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAILURE = 'ADD_DEVICE_FAILURE';

export const ADD_SELECTED_BLA_TO_REDUX = 'ADD_SELECTED_BLA_TO_REDUX';
export const ADD_SELECTED_DEVICE_TO_REDUX = 'ADD_SELECTED_DEVICE_TO_REDUX';

export const GET_ALL_DEVICES_LIST_BY_BLA_ID_SUCCESS =
    'GET_ALL_DEVICES_LIST_BY_BLA_ID_SUCCESS';

export const GET_ALL_DEVICES_LIST_BY_BLA_ID = 'GET_ALL_DEVICES_LIST_BY_BLA_ID';
export const GET_ALL_TAG_LIST_BY_DEVICE_SUCCESS =
    'GET_ALL_TAG_LIST_BY_DEVICE_SUCCESS';

export const GET_MODEL_LIST = 'GET_MODEL_LIST';
export const GET_MODEL_LIST_LOADING = 'GET_MODEL_LIST_LOADING';
export const GET_MODEL_LIST_FAILURE = 'GET_MODEL_LIST_FAILURE';
export const GET_MODEL_LIST_SUCCESS = 'GET_MODEL_LIST_SUCCESS';

export const GET_NODE_LIST = 'GET_NODE_LIST';
export const GET_NODE_LIST_LOADING = 'GET_NODE_LIST_LOADING';
export const GET_NODE_LIST_FAILURE = 'GET_NODE_LIST_FAILURE';
export const GET_NODE_LIST_SUCCESS = 'GET_NODE_LIST_SUCCESS';

export const GET_STREAM_TAG_LIST = 'GET_STREAM_TAG_LIST';
export const GET_STREAM_TAG_LIST_LOADING = 'GET_STREAM_TAG_LIST_LOADING';
export const GET_STREAM_TAG_LIST_FAILURE = 'GET_STREAM_TAG_LIST_FAILURE';
export const GET_STREAM_TAG_LIST_SUCCESS = 'GET_STREAM_TAG_LIST_SUCCESS';

export const GET_AGGREGATED_CHART_DATA = 'GET_AGGREGATED_CHART_DATA';
export const GET_AGGREGATED_CHART_DATA_LOADING =
    'GET_AGGREGATED_CHART_DATA_LOADING';
export const GET_AGGREGATED_CHART_DATA_FAILURE =
    'GET_AGGREGATED_CHART_DATA_FAILURE';
export const GET_AGGREGATED_CHART_DATA_SUCCESS =
    'GET_AGGREGATED_CHART_DATA_SUCCESS';

export const CLEAR_DATA_VISUALIZATION_VALUES =
    'CLEAR_DATA_VISUALIZATION_VALUES';

export const GET_ASSET_CSV_DATA = 'GET_ASSET_CSV_DATA';
export const GET_ASSET_CSV_DATA_FAILURE = 'GET_ASSET_CSV_DATA_FAILURE';
export const GET_ASSET_CSV_DATA_SUCCESS = 'GET_ASSET_CSV_DATA_SUCCESS';

export const GET_AGGREGRATED_EXPORT_CSV_DATA = 'GET_AGGREGRATED_EXPORT_CSV_DATA';
export const GET_AGGREGRATED_EXPORT_CSV_DATA_FAILURE = 'GET_AGGREGRATED_EXPORT_CSV_DATA_FAILURE';
export const GET_AGGREGRATED_EXPORT_CSV_DATA_SUCCESS = 'GET_AGGREGRATED_EXPORT_CSV_DATA_SUCCESS';

export const GET_DATA_TABLE_LIST = 'GET_DATA_TABLE_LIST';
export const GET_DATA_TABLE_LIST_FAILURE = 'GET_DATA_TABLE_LIST_FAILURE';
export const GET_DATA_TABLE_LIST_SUCCESS = 'GET_DATA_TABLE_LIST_SUCCESS';

export const CLEAR_DATA_TABLE_LIST = 'CLEAR_DATA_TABLE_LIST';


// Data Visualization
export const EXPORT_DATA_VISUALIZATION = 'EXPORT_DATA_VISUALIZATION';
export const EXPORT_DATA_VISUALIZATION_SUCCESS =
    'EXPORT_DATA_VISUALIZATION_SUCCESS';
export const EXPORT_DATA_VISUALIZATION_FAILURE =
    'EXPORT_DATA_VISUALIZATION_FAILURE';

export const GET_KPI_INFO='GET_KPI_INFO';
export const GET_KPI_INFO_SUCCESS='GET_KPI_INFO_SUCCESS';
export const GET_KPI_INFO_FAILURE='GET_KPI_INFO_FAILURE';

export const GET_KPI_TRENDS='GET_KPI_TRENDS';
export const GET_KPI_TRENDS_SUCCESS='GET_KPI_TRENDS_SUCCESS';
export const GET_KPI_TRENDS_FAILURE='GET_KPI_TRENDS_FAILURE';

export const EMPTY_TAGS_PLOTTING='EMPTY_TAGS_PLOTTING';
export const EMPTY_KPIS_PLOTTING='EMPTY_KPIS_PLOTTING';