import { Drawer, Row, Col, Button, Divider, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, EMPTY } from 'types/enums';
import { useState, useEffect } from 'react';
import {
    putNodeTypeUpdate,
    setNodeData,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const AddNode: React.FC<any> = ({
    open,
    setAddNodeForm,
    editNode,
    openNodeInfo,
    addNodeForm,
    setOpenDrawer,
    setOpenNodeInfo,
    setEditNode,
    setSearchState,
    setPage,
    setPageSize,
}) => {
    const { t } = useTranslation('translation');
    const [name, setName] = useState<string>(EMPTY.string);
    const [description, setDescription] = useState<string>(EMPTY.string);
    const [isNameEmpty, setIsNameEmpty] = useState<boolean>(true);
    const [isDescriptionChanged, setIsDescriptionChanged] = useState<boolean>(false); // Track if description is changed
    const dispatch = useDispatch();
    const nodeTypeId = useSelector(
        (state: any) => state.assetModelling?.nodeType?.nodeTypeById
    );
    const [form] = Form.useForm();
    const initialValues = addNodeForm
        ? { name: '', description: '' }
        : {
              name: nodeTypeId?.name || '',
              description: nodeTypeId?.description ?? '',
          };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [nodeTypeId]);

    const handleNameChange = (value: string) :any => {
        setName(value);
        setIsNameEmpty(value.trim().length === 0);
    };

    const handleDescriptionChange = (value: string) :any => {
        setDescription(value);
        setIsDescriptionChanged(value.trim().length >= 0); 
    };

    const addNode = (): any => {
        setAddNodeForm(false);
        setOpenDrawer(false);
        setEditNode(false)
    };

    return (
        <>
            <Drawer
                className="addNodeDrawer"
                placement="right"
                size="default"
                getContainer={false}
                closable={false}
                open={open}
            >
                <>
                    <div>
                        <Row>
                            <Col span={openNodeInfo ? 18 : 22}>
                                <Row>
                                    <Col
                                        span={24}
                                        className="addNodeDrawer__heading fw-500 fs-16"
                                    >
                                        <span>
                                            {editNode &&
                                                t(
                                                    'assetModeller.configuration.editNodeType'
                                                )}
                                            {openNodeInfo &&
                                                t(
                                                    'assetModeller.configuration.viewNodeType'
                                                )}
                                            {addNodeForm &&
                                                t(
                                                    'assetModeller.configuration.createNewNodeType'
                                                )}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={openNodeInfo ? 6 : 2}
                                className={
                                    openNodeInfo && 'addNodeDrawer__editBtn'
                                }
                            >
                                {openNodeInfo && (
                                    <Button
                                        onClick={() => {
                                            setEditNode(true);
                                            setOpenNodeInfo(false);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                )}
                                <Button
                                    className="addNodeDrawer__icon"
                                    type="text"
                                    onClick={() => {
                                        setAddNodeForm(false);
                                        setOpenNodeInfo(false);
                                        setEditNode(false);
                                        setOpenDrawer(false);
                                    }}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Divider className="addNodeDrawer__divider" />
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={addNode}
                            initialValues={initialValues}
                        >
                            <Form.Item
                                name="name"
                                label={t(
                                    'assetModeller.configuration.nodeTypeName'
                                )}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Node Type Name is required',
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (
                                                value &&
                                                value.trimStart() !== value
                                            ) {
                                                throw new Error(
                                                    'Node Type Name cannot start with a space'
                                                );
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    className="addNodeDrawer__inputField"
                                    disabled={openNodeInfo}
                                    onChange={(e) => {handleNameChange(e.target.value)}}
                                />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={t(
                                    'assetModeller.configuration.description'
                                )}
                            >
                                <Input
                                    className="addNodeDrawer__inputField"
                                    disabled={openNodeInfo}
                                    onChange={(e) => {handleDescriptionChange(e.target.value)}}
                                />
                            </Form.Item>
                            {!openNodeInfo && (
                                <div className="addNodeDrawer__button">
                                    <Divider />
                                    <Row className="addNodeDrawer__footerButtons">
                                        <Col
                                            span={8}
                                            className="addNodeDrawer__cancelButton"
                                        >
                                            <CustomButton
                                                type={BUTTONTYPE?.cancel}
                                                disabled={false}
                                                handleClick={() => {
                                                    setAddNodeForm(false);
                                                    setOpenDrawer(false);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            span={8}
                                            className="addNodeDrawer__createNodeButton"
                                        >
                                            <CustomButton
                                                type={
                                                    addNodeForm
                                                        ? BUTTONTYPE?.createNode
                                                        : BUTTONTYPE?.save
                                                }
                                                typeOfButton={'submit'}
                                                disabled={isNameEmpty && !isDescriptionChanged}
                                                handleClick={(e: {
                                                    preventDefault: () => void;
                                                }) => {
                                                    if (addNodeForm) {
                                                        dispatch(
                                                            setNodeData({
                                                                name,
                                                                description,
                                                                categories: [],
                                                            })
                                                        );
                                                        setPage(PAGE);
                                                        setPageSize(PAGE_SIZE);
                                                        setSearchState(
                                                            EMPTY.string
                                                        );
                                                    } else {
                                                        dispatch(
                                                            putNodeTypeUpdate({
                                                                name,
                                                                description,
                                                                id: nodeTypeId?.id,
                                                                categories: [],
                                                            })
                                                        );
                                                        setPage(PAGE);
                                                        setPageSize(PAGE_SIZE);
                                                        setSearchState(
                                                            EMPTY.string
                                                        );
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form>
                    </div>
                </>
            </Drawer>
        </>
    );
};
export default AddNode;
