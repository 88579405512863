import Table, { type ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { useSelector } from 'react-redux';
import { dataVisualization, sortOrder } from 'types/enums';
import { Spin, message } from 'antd';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useTranslation } from 'react-i18next';
import { maxRowSelectionCount } from 'utils/constants';

const TagTable: React.FC<any> = ({
    data,
    setSelectedTagRows,
    selectedTagRows,
    search,
    setSortColumn,
    setSortOrder,
    selectedDevice,
    maxRange
}) => {
    const { t } = useTranslation('translation');

    const [tableData, setTableData] = useState<any>([]);
    const tagDataLoader: any = useSelector(
        (state: any) => state.dataVisualization.tagDataLoader
    );
    const tableDataMapper = (): [] => {
        const temp: any = [];
        data?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.uuid,
                name: item.tagName,
            });
        });
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [data]);
    const SearchNoDataText = (
        <EmptyDataComponent
            customClassName="SearchEmptyComponent"
            textValue={t('dataVisualization.noResultText')}
            loading={tagDataLoader}
        />
    );
    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            if (maxRange && selected) {
                message.info( `Maximum selectable rows are ${maxRowSelectionCount}`);
                return;
            }
            if (selected) {
                setSelectedTagRows([...selectedTagRows, record]);
            } else {
                setSelectedTagRows(
                    selectedTagRows.filter(
                        (item: any) => item.uuid !== record.uuid
                    )
                );
            }
        },
        selectedRowKeys: selectedTagRows?.map((item: any) => item.uuid),
    };
    const TableColumns: ColumnsType<any> = [
        {
            key: 'tagName',
            title: (
                <>
                    <div className="blaTableWrapper__columnTitle">
                        <div>{t('dataVisualization.tagName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.ascending);
                                    setSortColumn(dataVisualization.TAG_NAME);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.descending);
                                    setSortColumn(dataVisualization.TAG_NAME);
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            dataIndex: 'tagName',
            width: 130,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>{record?.tagName}</div>
                    </>
                );
            },
        },
        {
            key: 'assetName',
            width: 100,
            title: (
                <>
                    <div className="blaTableWrapper__columnTitle">
                        <div>{t('dataVisualization.assetName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.ascending);
                                    setSortColumn(dataVisualization.NODE_NAME);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.descending);
                                    setSortColumn(dataVisualization.NODE_NAME);
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            dataIndex: 'assetName',
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>{record?.parentNode?.name}</div>
                    </>
                );
            },
        },
    ];
    return (
        <>
            {tagDataLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        selectedRowKeys: selectedTagRows?.map((item: any) => {
                            return item?.uuid;
                        }),
                    }}
                    pagination={false}
                    columns={TableColumns}
                    size="small"
                    dataSource={selectedDevice && tableData}
                    locale={search?.length && { emptyText: SearchNoDataText }}
                    scroll={{ y: 'calc(100vh - 420px)' }}
                />
            )}
        </>
    );
};
export default TagTable;
