import { Card, Row, Col, Spin } from 'antd';
import './index.scss';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE } from 'types/enums';
import ModelOverviewTable from './ModelOverviewTable';
import { useEffect, useState } from 'react';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import AddModel from './AddModelForm';
import ModalTabsSection from './modelTabSections';
import { useDispatch, useSelector } from 'react-redux';
import {
    getModelDetailsById,
    getModelList,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import {
    CLEAR_MODEL_DETAILS,
    OPEN_SUB_MODEL_TABS,
} from 'redux/types/assetModellingTypes';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import CustomPagination from 'components/common/CustomPagination';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ModelOverview: React.FC<any> = () => {
    const { t } = useTranslation('translation');
    const { subCurrentTab } = useParams();
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const [addModelForm, setAddModelForm] = useState(false);
    const [openModelInfo, setOpenModelInfo] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [recordDetails, setRecordDetails] = useState({});
    const [payload, setPayload] = useState({
        page,
        pageSize,
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (!openModelInfo)
            dispatch(
                getModelList({
                    ...payload,
                })
            );
    }, [openModelInfo]);
    useEffect(() => {
        subCurrentTab &&
            dispatch({
                type: OPEN_SUB_MODEL_TABS,
            }) &&
            dispatch(getModelDetailsById(subCurrentTab)) &&
            setOpenModelInfo(true);
    }, [subCurrentTab]);
    const modelList = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelList
    );
    const modelListLoading = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelListLoading
    );
    const openSubModelTabs = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.openSubModelTabs
    );
    useEffect(() => {
        setPayload({ ...payload, page, pageSize });
    }, [page, pageSize]);
    useEffect(() => {
        if (openSubModelTabs === false) dispatch({ type: CLEAR_MODEL_DETAILS });
    }, [openSubModelTabs]);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    useEffect(() => {
        dispatch(
            getModelList({
                sortOrder: sortOrder,
                sortColumn: sortColumn,
                page: page,
                pageSize: pageSize,
            })
        );
    }, [sortColumn, sortOrder, page, pageSize]);
    return (
        <>
            {openSubModelTabs ? (
                <div>
                    <ModalTabsSection
                        editModel={editModel}
                        setOpenModelInfo={setOpenModelInfo}
                        setEditModel={setEditModel}
                        recordDetails={recordDetails}
                        setPage={setPage}
                    />
                </div>
            ) : (
                <>
                    <div className="modelOverviewWrapper">
                        <Card bordered={false}>
                            <>
                                {modelList?.totalRecords > 0 ? (
                                    <>
                                        <Row>
                                            <Col span={24}>
                                                <div className="modelOverviewWrapper__addModelBtn">
                                                    <CustomButton
                                                        type={
                                                            BUTTONTYPE.addModel
                                                        }
                                                        disabled={false}
                                                        handleClick={() => {
                                                            setAddModelForm(
                                                                true
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {modelListLoading ? (
                                            <div className="view__loader">
                                                <Spin />
                                            </div>
                                        ) : (
                                            <Row>
                                                <Col span={24}>
                                                    <div className="modelOverviewTable">
                                                        <ModelOverviewTable
                                                            setOpenModelInfo={
                                                                setOpenModelInfo
                                                            }
                                                            setEditModel={
                                                                setEditModel
                                                            }
                                                            data={
                                                                modelList?.records
                                                            }
                                                            setRecordDetails={
                                                                setRecordDetails
                                                            }
                                                            payload={payload}
                                                            setSortOrder={
                                                                setSortOrder
                                                            }
                                                            setSortColumn={
                                                                setSortColumn
                                                            }
                                                            setPage={setPage}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                ) : (
                                    <div className="modelOverviewWrapper__tableEmpty">
                                        <EmptyDataComponent
                                            textValue={t('assetModeller.modelOverview.noModelText')}
                                            buttonType={{
                                                name: 'New Model',
                                                disable: false,
                                            }}
                                            buttonClickHandler={() => {
                                                setAddModelForm(true);
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                            {addModelForm && (
                                <AddModel
                                    open={addModelForm}
                                    setAddModelForm={setAddModelForm}
                                    setPage={setPage}
                                    setOpenModelInfo={setOpenModelInfo}
                                />
                            )}
                        </Card>
                    </div>
                    {modelList?.totalRecords > PAGE_SIZE && (
                        <CustomPagination
                            totalRecords={modelList?.totalRecords}
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                    )}
                </>
            )}
        </>
    );
};
export default ModelOverview;
