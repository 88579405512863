import { ReactComponent as ConnectIcon } from 'assets/icons/connect.svg';
import { ReactComponent as DataExplorer } from 'assets/icons/dataExplorer.svg';
import { ReactComponent as UserManagementIcon } from 'assets/icons/userManagement.svg';
import { ReactComponent as DeviceConnectionIcon } from 'assets/icons/deviceManagement.svg';
import { ReactComponent as TransformIcon } from 'assets/icons/transform.svg';
import { ReactComponent as AssetModellerIcon } from 'assets/icons/assetModeller.svg';
import { ReactComponent as ContextualizationIcon } from 'assets/icons/contextualizationIcon.svg';
import { ReactComponent as DataTablesIcon } from 'assets/icons/DataTable.svg';
import { ReactComponent as BlaIcon } from 'assets/icons/BLA.svg';
import { ReactComponent as DeviceIcon } from 'assets/icons/device.svg';
import { ReactComponent as TagsIcon } from 'assets/icons/tags.svg';
import { ReactComponent as UserIcon } from 'assets/icons/userIcon.svg';
import { ReactComponent as RoleIcon } from 'assets/icons/roles.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as DataVisualization } from 'assets/icons/dataVisualization.svg';
import { ReactComponent as DashboardBuilder } from 'assets/icons/dashboardBuilderIcon.svg';
import { ReactComponent as ReportHeader } from 'assets/icons/reportingHeader.svg';
import { ReactComponent as UseCases } from 'assets/icons/useCases.svg';
import { ReactComponent as BoilerEfficiencyMonitoring } from 'assets/icons/boilerEfficiencyMonitoring.svg';
import { ReactComponent as NocilDashboard } from 'assets/icons/nocilDashboard.svg';
import { ReactComponent as ManualEntry } from 'assets/icons/manualEntry.svg';
import { ReactComponent as OperatorEntry } from 'assets/icons/operatorEntry.svg';
import { ReactComponent as Groups } from 'assets/icons/groups.svg';
import { ReactComponent as Settings } from 'assets/icons/sideNavSettingsIcon.svg';
import { ReactComponent as ConfigIcon } from 'assets/icons/config.svg';
import { ReactComponent as ChemicalProcessIcon } from 'assets/icons/ChemicalProcess.svg';
import { ReactComponent as BusinessIntIcon } from 'assets/icons/businessIntegrator.svg';
import { ReactComponent as Attribute } from 'assets/icons/Attribute.svg';
import { ReactComponent as CustomContextIcon } from 'assets/icons/customContext.svg';
import { ReactComponent as KpiIcon } from 'assets/icons/kpiIcon.svg';
import { ReactComponent as TrendingIcon } from 'assets/icons/trending.svg';
import { ReactComponent as ConnectionsIcon } from 'assets/icons/connectionsIcon.svg';
import { ReactComponent as MonitoringIcon } from 'assets/icons/monitoringIcon.svg';
import { ReactComponent as Monitoring } from 'assets/icons/Monitoring.svg';
import { ReactComponent as OperationsIcon } from 'assets/icons/operationsIcon.svg';
import { ReactComponent as MappingIcon } from 'assets/icons/mappingIcon.svg';
import { ReactComponent as AttributeIcon } from 'assets/icons/attributeIcon.svg';
import { ReactComponent as TableIcon } from 'assets/icons/tableIcon.svg';
import { ReactComponent as TimeCapsuleDefinationIcon } from 'assets/icons/TimeCapsuleImplementation.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import { ReactComponent as ImplementationIcon } from 'assets/icons/implementationIcon.svg';
import { ReactComponent as ManualFormIcon } from 'assets/icons/manualForm.svg';
import { ReactComponent as ReasonCodeIcon } from 'assets/icons/reasonCodeEntry.svg';
import { ReactComponent as CalenderConfig } from 'assets/icons/calendarNew.svg';
import { ReactComponent as UOMIcon } from 'assets/icons/uom-configurator.svg';
import { ReactComponent as AutomateIcon } from 'assets/icons/automate.svg';
import { ReactComponent as WorkflowIcon } from 'assets/icons/workflow.svg';
import { ReactComponent as DeviceListIcon } from 'assets/icons/deviceList.svg';

import { Tag } from 'antd';
import './index.scss';

export const data = [
    {
        name: 'Home',
        breadcrumb: 'Home',
        key: 'Home',
        icon: <HomeIcon />,
        path: '/home',
        exact: true,
    },
    {
        id: 51,
        name: 'Dashboards',
        breadcrumb: 'Dashboards',
        key: 'Dashboards',
        icon: <ReportHeader />,
        exact: true,
    },
    {
        id: 4,
        name: (
            <>
                {'Brabo AI Assistant   '}
                <span className="betaTag">
                    <Tag color="blue">Beta</Tag>
                </span>
            </>
        ),
        breadcrumb: 'Brabo AI Assistant',
        key: 'Brabo AI Assistant',
        icon: <BusinessIntIcon />,
        path: '/brabo-assistant',
        exact: true,
        children: [],
    },
    {
        id: 73,
        name: 'Manual Forms',
        breadcrumb: 'Manual Forms',
        key: 'Manual Forms',
        icon: <ManualFormIcon />,
        exact: true,
        children: [
            {
                id: 74,
                name: 'Line 3 - Reason Code Entry',
                breadcrumb: 'Line 3 - Reason Code Entry',
                key: 'reason-code',
                icon: <ReasonCodeIcon />,
                path: '/manual-forms/reason-code',
                exact: true,
                children: [],
            },
        ],
    },
    {
        id: 71,
        name: 'Connect',
        breadcrumb: 'Connect',
        key: 'Connect',
        icon: <ConnectIcon />,
        path: '/device-management',
        exact: true,
        children: [
            {
                id: 70,
                name: 'Device Management',
                breadcrumb: 'Device Management',
                key: 'Device Management',
                icon: <DeviceConnectionIcon />,
                exact: true,
                children: [
                    {
                        id: 4,
                        name: 'BLAs',
                        breadcrumb: 'BLAs',
                        key: 'BLA',
                        icon: <BlaIcon />,
                        path: '/device-management/blas',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 5,
                        name: 'Devices',
                        breadcrumb: 'Devices',
                        key: 'Devices',
                        icon: <DeviceIcon />,
                        path: '/device-management/devices',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 6,
                        name: 'Tags',
                        breadcrumb: 'Tags',
                        key: 'Tags',
                        icon: <TagsIcon />,
                        path: '/device-management/tags',
                        exact: true,
                        children: [],
                    },
                ],
            },
            {
                id: 78,
                name: 'Business Integrator',
                breadcrumb: 'Business Integrator',
                key: 'Business Integrator',
                icon: <BusinessIntIcon />,
                exact: true,
                children: [
                    {
                        id: 79,
                        name: 'Connections',
                        breadcrumb: 'Connections',
                        key: 'Connections',
                        icon: <ConnectionsIcon />,
                        exact: true,
                        children: [],
                    },
                    {
                        id: 80,
                        name: 'Mapping',
                        breadcrumb: 'Mapping',
                        key: 'Mapping',
                        icon: <MappingIcon />,
                        exact: true,
                        children: [],
                    },
                    {
                        id: 81,
                        name: 'Operations',
                        breadcrumb: 'Operations',
                        key: 'Operations',
                        icon: <OperationsIcon />,
                        exact: true,
                        children: [],
                    },
                    {
                        id: 82,
                        name: 'Monitoring',
                        breadcrumb: 'Monitoring',
                        key: 'Monitoring',
                        icon: <MonitoringIcon />,
                        exact: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 74,
        name: 'Transform',
        breadcrumb: 'Transform',
        key: 'Transform',
        icon: <TransformIcon />,
        path: '/transform',
        exact: true,
        children: [
            {
                id: 75,
                name: 'Contextualization',
                breadcrumb: 'Contextualization',
                key: 'Contextualization',
                icon: <ContextualizationIcon />,
                path: '/transform/contextualization',
                exact: true,
                children: [
                    {
                        id: 76,
                        name: 'Asset Modeller',
                        breadcrumb: 'Asset Modeller',
                        key: 'Asset Modeller',
                        icon: <AssetModellerIcon />,
                        exact: true,
                        path: '/transform/contextualization',
                        children: [],
                    },
                    {
                        id: 77,
                        name: 'Custom Context',
                        breadcrumb: 'Custom Context',
                        key: 'Custom Context',
                        icon: <CustomContextIcon />,
                        exact: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 165,
        name: 'Configure',
        breadcrumb: 'Configure',
        key: 'Configure',
        icon: <ConfigIcon />,
        exact: true,
        children: [
            {
                id: 166,
                name: 'Data Dictionary',
                breadcrumb: 'Data Dictionary',
                key: 'Data-Dictionary',
                icon: <DeviceConnectionIcon />,
                path: '/configure/data-dictionary',
                exact: true,
                children: [
                    {
                        id: 167,
                        name: 'Attribute',
                        breadcrumb: 'Attribute',
                        key: 'Attribute Definition',
                        icon: <Attribute />,
                        path: '/configure/data-dictionary/attribute',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 168,
                        name: 'Time Capsule',
                        breadcrumb: 'Time Capsule',
                        key: 'Time Capsule Definition',
                        icon: <TimeCapsuleDefinationIcon />,
                        path: '/configure/data-dictionary/time-capsule',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 169,
                        name: 'Table',
                        breadcrumb: 'Table',
                        key: 'Table Definition',
                        icon: <TableIcon />,
                        path: '/configure/data-dictionary/table',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 170,
                        name: 'KPIs',
                        breadcrumb: 'KPIs',
                        key: 'KPI Definition',
                        icon: <KpiIcon />,
                        path: '/configure/data-dictionary/kpi',
                        exact: true,
                        children: [],
                    },
                ],
            },
            {
                id: 14,
                name: 'Implementation',
                breadcrumb: 'Implementation',
                key: 'Implementation',
                icon: <ImplementationIcon />,
                path: '/configure/implementation',
                exact: true,
                children: [
                    {
                        id: 85,
                        name: 'Attribute',
                        breadcrumb: 'Attribute',
                        key: 'Attribute Implementation',
                        icon: <AttributeIcon />,
                        path: '/configure/implementation/attribute',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 86,
                        name: 'Time Capsule',
                        breadcrumb: 'Time Capsule',
                        key: 'Time Capsule Implementation',
                        icon: <TimeCapsuleDefinationIcon />,
                        path: '/configure/implementation/time-Capsule',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 87,
                        name: 'Table',
                        breadcrumb: 'Table',
                        key: 'Table Implementation',
                        icon: <TableIcon />,
                        path: '/configure/implementation/table',
                        exact: true,
                        children: [],
                    },
                ],
            },
            {
                id: 73,
                name: 'KPIs',
                breadcrumb: 'KPIs',
                key: 'KPIs',
                icon: <KpiIcon />,
                exact: true,
                children: [
                    {
                        id: 88,
                        name: 'KPI Implementation',
                        breadcrumb: 'KPI',
                        key: 'KPI Implementation',
                        icon: <KpiIcon />,
                        path: '/configure/kpis/kpi-implementation',
                        exact: true,
                        children: [],
                    },
                ],
            },
            {
                id: 15,
                name: 'Dashboard Builder',
                breadcrumb: 'Dashboard Builder',
                key: 'Dashboard Builder',
                icon: <DashboardBuilder />,
                path: '/dashboard-builder',
                exact: true,
                disabled: false,
                children: [],
            },
            // not in use will use
            // {
            //     id: 55,
            //     name: 'Dashboard Reporting',
            //     breadcrumb: 'Dashboard Reporting',
            //     key: 'Dashboard Reporting',
            //     icon: <ReportHeader />,
            //     exact: true,
            //     children: [
            //         {
            //             id: 72,
            //             name: 'Report Configurator',
            //             breadcrumb: 'Report Configurator',
            //             key: 'Report Configuration',
            //             icon: <ReportConfigurator />,
            //             path: '/report-configurator',
            //             exact: true,
            //         },
            //         {
            //             id: 16,
            //             name: 'Reports',
            //             breadcrumb: 'Reports',
            //             key: 'Reports',
            //             icon: <ReportConfigurator />,
            //             path: '/reports',
            //             exact: true,
            //         },
            //     ],
            // },
            // {
            //     id: 73,
            //     name: 'Forms',
            //     breadcrumb: 'Forms',
            //     key: 'Forms',
            //     icon: <FormIcon />,
            //     exact: true,
            //     children: [],
            // },
            {
                id: 74,
                name: 'Alerts',
                breadcrumb: 'Alerts',
                key: 'Alerts',
                icon: <DeviceListIcon />,
                path: '/configure/alert',
                exact: true,
                children: [],
            },
        ],
    },
    {
        id: 61,
        name: 'Automate',
        breadcrumb: 'Automate',
        key: 'Automate',
        icon: <AutomateIcon />,
        exact: true,
        children: [
            {
                id: 60,
                name: 'Workflows',
                breadcrumb: 'Workflows',
                key: 'Workflows',
                icon: <WorkflowIcon />,
                exact: true,
            },
            {
                id: 59,
                name: 'Notifications',
                breadcrumb: 'Notifications',
                key: 'Notifications',
                icon: <NotificationIcon />,
                exact: true,
            },
        ],
    },
    {
        id: 58,
        name: 'Data Explorer',
        breadcrumb: 'Data Explorer',
        key: 'Data Explorer',
        icon: <DataExplorer />,
        exact: true,
        children: [
            {
                id: 11,
                name: 'Data Visualization',
                breadcrumb: 'Data Visualization',
                key: 'Data Visualization',
                icon: <DataVisualization />,
                exact: true,
                children: [
                    {
                        id: 162,
                        name: 'Trending',
                        breadcrumb: 'Trending',
                        key: 'Trending',
                        icon: <TrendingIcon />,
                        path: '/data-visualization/trending',
                        exact: true,
                    },
                    {
                        id: 163,
                        name: 'Data Table',
                        breadcrumb: 'Data Table',
                        key: 'Data Table',
                        icon: <DataTablesIcon />,
                        path: '/data-visualization/data-table',
                        exact: true,
                    },
                ],
            },
        ],
    },
    {
        id: 57,
        name: 'Use Cases',
        breadcrumb: 'Use Cases',
        key: 'Use Cases',
        icon: <UseCases />,
        exact: true,
        children: [
            {
                id: 56,
                name: 'CPG OEE Monitoring',
                breadcrumb: 'CPG OEE Monitoring',
                key: 'CPG OEE Monitoring',
                icon: <Monitoring />,
                exact: true,
                children: [],
            },
            {
                id: 59,
                name: 'Boiler Efficiency Monitoring',
                breadcrumb: 'Boiler Efficiency Monitoring',
                key: 'Boiler Efficiency Monitoring',
                icon: <ChemicalProcessIcon />,
                exact: true,
                children: [],
            },
            // menus hidden for now
            // {
            //     id: 55,
            //     name: 'Chemical Process Monitoring',
            //     breadcrumb: 'Chemical Process Monitoring',
            //     key: 'aarti_apple',
            //     icon: <UserIcon />,
            //     exact: true,
            //     children: [],
            // },
            // {
            //     id: 53,
            //     name: 'Critical Asset Monitoring',
            //     breadcrumb: 'Critical Asset Monitoring',
            //     key: 'eberspacher',
            //     icon: <UserIcon />,
            //     path: '/use-cases/robotic-arm',
            //     exact: true,
            //     children: [],
            // },
            {
                id: 52,
                name: 'Boiler Efficiency',
                breadcrumb: 'nocil',
                key: 'Boiler Efficiency',
                icon: <BoilerEfficiencyMonitoring />,
                exact: true,
                children: [
                    {
                        id: 9,
                        name: 'NOCIL Dashboard',
                        breadcrumb: 'nocil',
                        key: 'NOCIL Dashboard',
                        icon: <NocilDashboard />,
                        path: '/nocil/nocil-dashboard',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 10,
                        name: 'Manual Entry',
                        breadcrumb: 'nocil',
                        key: 'Manual Entry',
                        icon: <ManualEntry />,
                        path: '/nocil/manual-entry',
                        exact: true,
                        children: [],
                    },
                    {
                        id: 13,
                        name: 'Operator Entry',
                        breadcrumb: 'nocil',
                        key: 'Operator Entry',
                        icon: <OperatorEntry />,
                        path: '/nocil/operator-entry',
                        exact: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 51,
        name: 'User Management',
        breadcrumb: 'User Management',
        key: 'User Management',
        icon: <UserManagementIcon />,
        exact: true,
        children: [
            {
                id: 1,
                name: 'Users',
                breadcrumb: 'Users',
                key: 'Users',
                icon: <UserIcon />,
                path: '/user-management/users',
                exact: true,
                children: [],
            },
            {
                id: 2,
                name: 'Groups',
                breadcrumb: 'Groups',
                key: 'Resource Groups',
                icon: <Groups />,
                path: '/user-management/groups',
                exact: true,
                children: [],
            },
            {
                id: 3,
                name: 'Roles',
                breadcrumb: 'Roles',
                key: 'Roles',
                icon: <RoleIcon />,
                path: '/user-management/roles',
                exact: true,
                children: [],
            },
        ],
    },
    {
        id: 50,
        name: 'Settings',
        breadcrumb: 'Settings',
        key: 'Settings',
        icon: <Settings />,
        exact: true,
        children: [
            {
                id: 12,
                name: 'Calendar Configurator',
                breadcrumb: 'Calendar Configurator',
                key: 'Calendar Configuration',
                icon: <CalenderConfig />,
                path: '/settings/calendar-configurator',
                exact: true,
                children: [],
            },
            {
                id: 12,
                name: 'UOM Configurator',
                breadcrumb: 'UOM Configurator',
                key: 'UOM',
                icon: <UOMIcon />,
                path: '/settings/uom-configurator',
                exact: true,
                children: [],
            },
        ],
    },
];
