import { Popover } from 'antd';
import Table, { type ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import { MoreOutlined } from '@ant-design/icons';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import './index.scss';
import ConfigurationMoreContent from '../MoreContent';
import { useDispatch, useSelector } from 'react-redux';
import { DATE_ONLY_FORMAT, PAGE } from 'utils/constants';
import moment from 'moment';
import {
    getEquipmentClassId,
    setEquipmentState,
} from 'redux/actions/AssetModellingActions/assetModellingAction';

import {
    equipmentClassSortColumns,
    EquipmentState,
    sortingOrder,
} from 'types/enums';
import { useTranslation } from 'react-i18next';

const ConfigurationTable: React.FC<any> = ({
    payload,
    search,
    setSearchState,
    setPage,
    setPageSize,
    setSortOrder,
    setSortColumn,
}): any => {
    const { t } = useTranslation('translation');
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record?.key]: visible,
        }));
    };

    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [tableData, setTableData] = useState<any>([]);
    const dispatch = useDispatch();
    const equipmentClassList = useSelector(
        (state: any) => state.assetModelling?.equipment?.equipmentClassTableList
    );

    const tableDataMapper = (): [] => {
        const temp: any = [];
        equipmentClassList?.records?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item?.id,
            });
        });
        return temp;
    };

    useEffect(() => {
        setTableData(tableDataMapper());
    }, [equipmentClassList]);

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.id]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.id)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: DataType[]) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record) => record?.id
                );
                setSelectedRowIds(newSelectedIds);
            } else {
                setSelectedRowIds([]);
            }
        },
    };

    const [showPopover, setShowPopover] = useState(false);
    useEffect(() => {
        setShowPopover(selectedRowIds.length <= 1);
    }, [selectedRowIds]);
    const handlePopoverItemClick = (): void => {
        setPopoverVisible({ actions: false });
    };
    const columns: ColumnsType<any> = [
        {
            title: (
                <div>
                    <MoreOutlined />
                </div>
            ),
            key: 'more',
            width: 56,
            render: (_: any, configurationData: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                overlayClassName="configurationPopover"
                                visible={popoverVisible[configurationData?.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(
                                        visible,
                                        configurationData
                                    );
                                }}
                                placement="bottomLeft"
                                trigger={showPopover ? 'click' : []}
                                content={
                                    configurationData?.id ? (
                                        <ConfigurationMoreContent
                                            equipClassId={configurationData?.id}
                                            onItemClick={handlePopoverItemClick}
                                            setSearchState={setSearchState}
                                            setPage={setPage}
                                            setPageSize={setPageSize}
                                        />
                                    ) : null
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.equipmentClass')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.NAME);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.NAME);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'equipmentClass_name',
            width: '223px',
            render: (_: any, configurationData: any) => (
                <>
                    <div
                        className="attributeTableWrapper__nameData"
                        onClick={() => {
                            dispatch(setEquipmentState(EquipmentState.view));
                            dispatch(
                                getEquipmentClassId(configurationData?.id)
                            );
                        }}
                    >
                        <div className="blaTableWrapper__status">
                            <span className="fs-14 fw-500 name">
                                {configurationData?.name}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.description')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.DESCRIPTION);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.DESCRIPTION);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            className: 'column__description',
            width: '442px',
            render: (_: any, configurationData: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {configurationData?.description}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.lastModified')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.UPDATED_ON);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.UPDATED_ON);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'lastModified',
            key: 'lastModified',
            width: '200px',
            render: (_: any, configurationData: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {moment(configurationData?.updatedOn).format(
                            DATE_ONLY_FORMAT
                        )}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.createdOn')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.CREATED_ON);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(equipmentClassSortColumns?.CREATED_ON);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'createdOn',
            dataIndex: 'createdOn',
            width: '200px',
            render: (_: any, configurationData: any) => (
                <>
                    {moment(configurationData?.createdOn).format(
                        DATE_ONLY_FORMAT
                    )}
                </>
            ),
        },
    ];
    return (
        <>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                    selectedRowKeys: selectedRowIds,
                }}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ y: 'calc(100vh - 390px)' }}
            />
        </>
    );
};
export default ConfigurationTable;
