import {
    Drawer,
    Row,
    Col,
    Button,
    Switch,
    Divider,
    Form,
    Input,
    Select,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import './index.scss';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE } from 'types/enums';
import { useState } from 'react';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useDispatch } from 'react-redux';
import { addModelDetails } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useTranslation } from 'react-i18next';
const modelOptions = [
    {
        id: '1',
        value: 'ISA88_95',
        label: 'S88/95 Asset Hierarchy',
    },
    {
        id: '2',
        value: 'FLEXIBLE',
        label: 'Flexible Asset Model',
    },
];
const { Option } = Select;

const AddModel: React.FC<any> = ({
    open,
    setAddModelForm,
    setPage,
    setOpenModelInfo,
}) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const details = parseJwt();

    const [payload, setPayload] = useState({
        name: '',
        description: '',
        modelType: '',
        createdBy: details?.username,
        updatedBy: details?.username,
        umiIndex: 0,
        isPrimary: false,
    });
    const { name, description, modelType } = payload;
    const initialValues = {
        model: '',
        description: '',
        modelType: '',
    };
    const handleSwitchChange = (checked: boolean): any => {
        setPayload({
            ...payload,
            isPrimary: checked,
        });
    };
    const addModel = (): any => {
        dispatch(
            addModelDetails({ ...payload, page: PAGE, pageSize: PAGE_SIZE })
        );
        setPage(PAGE);
        setOpenModelInfo(false);
        setAddModelForm(false);
    };
    return (
        <>
            <Drawer
                className="addModelDrawer"
                placement="right"
                size="default"
                getContainer={false}
                closable={false}
                open={open}
            >
                <>
                    <div>
                        <Row>
                            <Col span={9}>
                                <Row>
                                    <Col
                                        span={24}
                                        className="addModelDrawer__heading fw-500 fs-16"
                                    >
                                        <span>{t('assetModeller.modelOverview.addNewModel')}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={13}
                                className="addModelDrawer__primaryModel"
                            >
                                <span>{t('assetModeller.modelOverview.primaryModel')}</span>
                                <Switch onChange={handleSwitchChange} />
                            </Col>
                            <Col span={2}>
                                <Button
                                    className="addModelDrawer__icon"
                                    type="text"
                                    onClick={() => {
                                        setAddModelForm(false);
                                    }}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Divider className="addModelDrawer__divider" />
                        <Form
                            layout="vertical"
                            onFinish={addModel}
                            initialValues={initialValues}
                        >
                            <Form.Item
                                name="modelName"
                                label={t('assetModeller.modelOverview.modelName')}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Model Name is required',
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (
                                                value &&
                                                value.trimStart() !== value
                                            ) {
                                                throw new Error(
                                                    'Model Name cannot start with a space'
                                                );
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    className="addModelDrawer__inputField"
                                    value={name}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setPayload({
                                            ...payload,
                                            name: e?.target?.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="description" label={t('assetModeller.modelOverview.description')}>
                                <Input
                                    className="addModelDrawer__inputField"
                                    value={description}
                                    onChange={(e: any) => {
                                        setPayload({
                                            ...payload,
                                            description: e?.target?.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="modelType"
                                label={t('assetModeller.modelOverview.modelType')}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Model Type is required',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value, option) => {
                                        setPayload({
                                            ...payload,
                                            modelType: value,
                                        });
                                    }}
                                    value={modelType}
                                >
                                    {modelOptions?.map((item: any) => (
                                        <Option
                                            key={item?.id}
                                            value={item?.value}
                                        >
                                            {item?.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <div className="addModelDrawer__button">
                                <Divider />
                                <Row className="addModelDrawer__footerButtons">
                                    <Col
                                        span={8}
                                        className="addModelDrawer__cancelButton"
                                    >
                                        <CustomButton
                                            type={BUTTONTYPE.cancel}
                                            disabled={false}
                                            handleClick={() => {
                                                setAddModelForm(false);
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        span={8}
                                        className="addModelDrawer__createModelButton"
                                    >
                                        <CustomButton
                                            disabled={false}
                                            type={BUTTONTYPE.createModel}
                                            typeOfButton={'submit'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </>
            </Drawer>
        </>
    );
};
export default AddModel;
