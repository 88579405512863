import { Button, Card, Col, Dropdown, Menu, Row } from 'antd';
import './index.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { DownloadOutlined } from '@ant-design/icons';
import MultiAxisTimeSeriesChart from 'components/common/charts/MultiAxisTimeSeriesChart';
import { getAssetCSVData, getExportAggregrateCsvData } from 'redux/actions/DataExplorer/DataVisualizationActions';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { TagOriginId, TemplateTypeAsset } from 'types/enums';

const ExpandedGraph: React.FC<any> = ({
    chartData,
    yAxisData,
    chartRef,
    dateRange,
    setShowExpandedGraph,
    uuids
}) => {
    const dispatch=useDispatch();
    const onCloseKPIHandler = (): any => {
        setShowExpandedGraph(false);
    };
    const startDateRedux = useSelector(
        (state: any) => state?.nocilDashboard?.filterValues?.startTime
    );
    const endDateRedux = useSelector(
        (state: any) => state?.nocilDashboard?.filterValues?.endTime
    );

    const getCSVData = (): any => {
        dispatch(
            getAssetCSVData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                templateType: TemplateTypeAsset.csv, // 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        );
    };
    const getPDFData = (): any => {
        dispatch(
            getAssetCSVData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                templateType: TemplateTypeAsset.pdf, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        );
    };
    const getCSVAggData = (): any => {
        dispatch(
            getExportAggregrateCsvData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                templateType: TemplateTypeAsset.csv, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        );
    };
    const getPDFAggData = (): any => {
        dispatch(
            getExportAggregrateCsvData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                templateType: TemplateTypeAsset.pdf, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        );
    };
    const menu = (
        <Menu onClick={({ key }): any => {}}>
            <Menu.Item key="csv" onClick={getCSVData}>
                Export Raw as CSV
            </Menu.Item>
            <Menu.Item key="pdf" onClick={getPDFData}>
                Export Raw as PDF
            </Menu.Item>
            <Menu.Item key="csvAgg" onClick={getCSVAggData}>
                Export Aggregated as CSV
            </Menu.Item>
            <Menu.Item key="pdfAgg" onClick={getPDFAggData}>
                Export Aggregated as PDF
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className="trendAreaWrapperScrolContent">
                <div className="trendAreaWrapper">
                    <Card
                        bordered={false}
                        className="trendAreaWrapper__cardContent"
                    >
                        <div className="trendAreaWrapper__cardBody">
                            <Row className="nocilWrapper-row" gutter={[16, 16]}>
                                <Col span={18}>
                                    <div className="title">
                                        <div className="heading fs-20 fw-500">
                                            Trend
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    span={6}
                                    className="trendAreaWrapper__cardContent__header-exportCancel"
                                >
                                    <div className="trendAreaWrapper__cardContent__header-exportButton">
                                        <Dropdown
                                            overlay={menu}
                                            trigger={['click']}
                                        >
                                            <Button>
                                                <DownloadOutlined />
                                                <span>Export</span>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div
                                        onClick={onCloseKPIHandler}
                                        className="closeIcon"
                                    >
                                        <CloseIcon />
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24} className="trendArea">
                                    <MultiAxisTimeSeriesChart
                                        chartSeriesData={chartData}
                                        yAxisSeriesData={yAxisData}
                                        chartRef={chartRef}
                                        dateRange={dateRange}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ExpandedGraph;
