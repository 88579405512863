import React, { useEffect, useState } from 'react';
import { Card, Row, Form } from 'antd';
import './index.scss';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import CustomButton from 'components/common/CustomButton';
import { cancelHandle, modalShow, okHandle } from 'utils/modalFunction';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUom,
    setUomState,
} from 'redux/actions/ConfigureActions/attributeActions';
import UomFormComponent from '../UomFormComponent';
import { parseJwt } from 'utils/jwtTokenFunction';
import { BUTTONTYPE, EMPTY, UOMTYPE, attributeFormEnum } from 'types/enums';
import { useTranslation } from 'react-i18next';

const CreateUom: React.FC<any> = ({
    paginatedPayload,
    setSortOrder,
    setSortColumn,
    setSearchState,
}) => {
    const [isSaveDisable, setIsSaveDisable] = useState(true);

    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const nameOfUnit = Form.useWatch(attributeFormEnum?.nameOfUnit, form);
    const abbreviation = Form.useWatch(attributeFormEnum?.abbreviation, form);
    const uomClasses = Form.useWatch(attributeFormEnum?.uomClasses, form);
    const metricSystem = Form.useWatch(attributeFormEnum?.metricSystem, form);
    const description = Form.useWatch(attributeFormEnum?.description, form);
    const details = parseJwt();
    const createUomResponse = useSelector(
        (state: any) => state?.configure?.attributes?.createUom
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);
    const onOkHandler = (): any => {
        if (onCancelClicked) {
            dispatch(setUomState(UOMTYPE.display));
            setOnCancelClicked(false);
        } else {
            dispatch(
                createUom({
                    name: nameOfUnit,
                    abbreviation: abbreviation,
                    desc: description,
                    uomClassId: Number.isInteger(uomClasses)
                        ? uomClasses
                        : null,
                    newUomClass: Number.isInteger(uomClasses)
                        ? null
                        : uomClasses,
                    metricSystemId: metricSystem,
                    requestedBy: details?.username,
                })
            );
        }
        localStorage.removeItem('recentUomClass');
        setSortOrder();
        setSortColumn();
        setSearchState();
        setSearchState(EMPTY.string);
    };

    useEffect(() => {
        if (createUomResponse) {
            // message.success(t('UomConfigurator.uomClassAddedSuccessMsg')); //will use later
            modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
        }
        okHandle(isModalOpen, setIsModalOpen);
    }, [createUomResponse]);

    useEffect(() => {
        if (
            nameOfUnit &&
            abbreviation &&
            uomClasses &&
            metricSystem &&
            nameOfUnit.trim() !== EMPTY.string &&
            abbreviation.trim() !== EMPTY.string
        ) {
            if (isSaveDisable) {
                setIsSaveDisable(false);
            }
        } else {
            if (!isSaveDisable) {
                setIsSaveDisable(true);
            }
        }
    }, [nameOfUnit, abbreviation, uomClasses, metricSystem]);
    const [onCancelClicked, setOnCancelClicked] = useState(false);

    const onHandlerCancel = (): any => {
        setOnCancelClicked(true);
    };
    useEffect(() => {
        if (onCancelClicked) {
            modalShow(isModalOpen, setIsModalOpen);
        }
    }, [onCancelClicked]);

    return (
        <Form
            className="attributeFormWrapper__form"
            form={form}
            layout="vertical"
            initialValues={{}}
        >
            <>
                <div className="createAttributeWrapper">
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: 0 }}
                        className="createAttributeWrapper__card"
                    >
                        <Row className="createAttributeWrapper__headerWrapper">
                            <div className="createAttributeWrapper__heading">
                                <div className="createAttributeWrapper__backIcon">
                                    <BackIcon
                                        onClick={() =>
                                            dispatch(
                                                setUomState(UOMTYPE.display)
                                            )
                                        }
                                    />
                                </div>
                                <div className="createAttributeWrapper__headingName fw-500 fs-20">
                                    {t('UomConfigurator.addNewUomPageTitle')}
                                </div>
                            </div>
                        </Row>
                        <div className="createAttributeWrapper__scrollContent">
                            <UomFormComponent form={form} />
                        </div>

                        <div className="createAttributeWrapper__createRoleFooter">
                            <div className="createAttributeWrapper__footerButtonWrapper">
                                <CustomButton
                                    type={BUTTONTYPE.cancel}
                                    disabled={false}
                                    handleClick={() => {
                                        onHandlerCancel();
                                    }}
                                />
                            </div>
                            <div className="createAttributeWrapper__footerButtonWrapper">
                                {
                                    <CustomButton
                                        type={BUTTONTYPE.save}
                                        disabled={isSaveDisable}
                                        handleClick={() => {
                                            modalShow(
                                                isModalOpen,
                                                setIsModalOpen
                                            );
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </Card>
                </div>
                <ConfirmationModal
                    open={isModalOpen}
                    icon={<ConfirmationIcon />}
                    onOk={() => onOkHandler()}
                    onCancel={() => {
                        cancelHandle(isModalOpen, setIsModalOpen);
                        setOnCancelClicked(false);
                    }}
                    text={
                        onCancelClicked
                            ? t('UomConfigurator.editModal.cancelClicked')
                            : t('UomConfigurator.editModal.saveChnages')
                    }
                />
                <SuccessfulModal
                    open={activeSuccessModalOpen}
                    onOk={() => onOkHandler()}
                    onCancel={() => {
                        dispatch(setUomState(UOMTYPE.display));
                        cancelHandle(
                            activeSuccessModalOpen,
                            setActiveSuccessModalOpen
                        );
                    }}
                    text={t('UomConfigurator.savedSuccesfully')}
                />
            </>
        </Form>
    );
};

export default CreateUom;
