import {
    Card,
    Row,
    Col,
    Button,
    Divider,
    Select,
    Cascader,
    Input,
    Radio,
} from 'antd';
import AssetGroups from './AssetGroups';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import OTTable from './OTTable';
import ITTable from './ITTable';
import { TagMappingSubTabs } from 'types/enums';
import FormsTable from './FormsTable';
import TagTable from './TagTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlasList } from 'redux/actions/DeviceManagementActions/blasAction';
import {
    getMappedTagsList,
    getTagListByDevice,
    mapTagNodes,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { getAllDevicesList } from 'redux/actions/DeviceManagementActions/deviceAction';
import './index.scss';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import CustomPagination from 'components/common/CustomPagination';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const TagMappingSection: React.FC<any> = ({ activeSubTabKey }) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllBlasList());
    }, []);

    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [selectedOption, setSelectedOption] = useState(null);
    const [radioDropdownOpen, setradioDropdownOpen] = useState(false);
    const [deviceListName, setDeviceListName] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);
    const [selectedDeviceValue, setSelectedDeviceValue] = useState<any>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [enableMapTagsBtn, setEnableMapTagsBtn] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [payload, setPayload] = useState({
        page,
        pageSize,
    });
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [filteredData, setFilteredData] = useState();

    const selectedAsset = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.selectedAsset
    );

    useEffect(() => {
        setPayload({ ...payload, page, pageSize });
    }, [page, pageSize]);
    useEffect(() => {
        selectedAsset?.key &&
            dispatch(
                getMappedTagsList({
                    ...payload,
                    nodeId: selectedAsset?.key,
                })
            );
    }, [payload, selectedAsset]);
    const allBlasList = useSelector(
        (state: any) => state?.deviceManagement?.blas?.allBlasList
    );
    useEffect(() => {
        selectedOption &&
            dispatch(getAllDevicesList({ blaId: selectedOption }));
    }, [selectedOption]);

    const allDevicesListByBla = useSelector(
        (state: any) => state?.deviceManagement?.devices?.allDevicesListByBla
    );

    const tagsMapped = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.tagsMapped
    );

    const mappedTagsList = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.mappedTagsList
    );

    const onSelectOption = (blaItem: any): any => {
        setSelectedOption(blaItem.blaId);
    };
    const onOpenChange = (openStatus: any): any => {
        setradioDropdownOpen(openStatus);
    };
    useEffect(() => {
        if (!selectedOption) {
            setDeviceListName([]);
            setSelectedDevice([]);
            setSelectedDeviceValue([]);
        } else {
            setDeviceListName(deviceListData());
        }
    }, [allDevicesListByBla, selectedOption]);
    useEffect(() => {
        tagsMapped && setSelectedRowIds([]);
    }, [tagsMapped]);
    useEffect(() => {
        if (selectedRowIds?.length > 0 && selectedAsset?.key) {
            setEnableMapTagsBtn(true);
        }
    }, [selectedRowIds, selectedAsset]);
    useEffect(() => {
        setSelectedDevice([]);
        setSelectedDeviceValue([]);
    }, [selectedOption]);
    const deviceListData = (): [] => {
        const temp: any = [];
        allDevicesListByBla?.map((item: any): any => {
            temp?.push({
                value: item?.deviceUUID,
                label: ` ${item?.deviceName}`,
            });
        });
        return temp;
    };

    const handleSelectedDeviceValue = (value: any): any => {
        setSelectedDevice(value);
        setSelectedDeviceValue(value.flat());
    };
    useEffect(() => {
        selectedDeviceValue &&
            dispatch(
                getTagListByDevice({ deviceUuidList: selectedDeviceValue })
            );
    }, [selectedDeviceValue]);
    const tagListByDevice = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.tagListByDevice
    );

    const handleSearchInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): any => {
        const searchTag = e.target.value.toLowerCase();
        const filtered = tagListByDevice?.content?.filter((item: any) => {
            return item?.tagName?.toLowerCase()?.includes(searchTag);
        });
        const filteredWithKeys = filtered?.map((item: any, index: any) => ({
            ...item,
            key: item?.uuid,
        }));

        setFilteredData(filteredWithKeys);
    };
    useEffect(() => {
        if (tagListByDevice?.content) {
            const finalData = tagListByDevice?.content.map(
                (item: any, index: any) => ({
                    ...item,
                    key: item?.uuid,
                })
            );
            setFilteredData(finalData);
        }
    }, [tagListByDevice]);
    useEffect(() => {
        selectedAsset?.key &&
            dispatch(
                getMappedTagsList({
                    nodeId: selectedAsset?.key,
                    sortColumn: sortColumn,
                    sortOrder: sortOrder,
                    page: page,
                    pageSize: pageSize,
                })
            );
    }, [sortColumn, sortOrder, page, pageSize]);
    return (
        <>
            <div>
                <Row className="tagMappingLeftCards">
                    <Col span={8} className="leftSection">
                        <Card
                            bordered={false}
                            className="leftSection__assetGroupCard"
                        >
                            <AssetGroups className="leftSection__assetGroupCard" />
                        </Card>
                        <Card
                            bordered={false}
                            className="leftSection__tagsCard"
                        >
                            <div className="tags__header">
                                <span>
                                    {t('assetModeller.modelOverview.tags')}
                                </span>
                                <Button
                                    className={'mapTagsBtn'}
                                    disabled={!enableMapTagsBtn ?? false}
                                    onClick={() => {
                                        setOpenConfirmationModal(true);
                                    }}
                                >
                                    {t('assetModeller.modelOverview.mapTags')}
                                </Button>
                            </div>
                            <Divider className="tags__divider" />
                            {activeSubTabKey === TagMappingSubTabs?.otTags && (
                                <>
                                    <div>
                                        <div className="tags__blaDeviceDropdown">
                                            <Select
                                                allowClear
                                                className="tags__selectBla"
                                                value={selectedOption}
                                                open={radioDropdownOpen}
                                                onDropdownVisibleChange={
                                                    onOpenChange
                                                }
                                                placeholder={t(
                                                    'assetModeller.modelOverview.selectBlaPlaceholder'
                                                )}
                                                onClear={() => {
                                                    setSelectedOption(null);
                                                }}
                                            >
                                                {Array.isArray(allBlasList) &&
                                                    allBlasList?.map(
                                                        (blaItem) => (
                                                            <Option
                                                                className="devicetags-option"
                                                                key={
                                                                    blaItem?.blaId
                                                                }
                                                                value={
                                                                    blaItem?.blaId
                                                                }
                                                            >
                                                                <Radio
                                                                    className="devicetags-radio"
                                                                    checked={
                                                                        selectedOption ===
                                                                        blaItem?.blaId
                                                                    }
                                                                    onChange={() =>
                                                                        onSelectOption(
                                                                            blaItem
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        blaItem?.name
                                                                    }
                                                                </Radio>
                                                            </Option>
                                                        )
                                                    )}
                                            </Select>
                                            <Cascader
                                                multiple
                                                options={deviceListName}
                                                onChange={
                                                    handleSelectedDeviceValue
                                                }
                                                placeholder={t(
                                                    'assetModeller.modelOverview.selectDevicePlaceholder'
                                                )}
                                                className="deviceSearchBox"
                                                maxTagCount="responsive"
                                                value={selectedDevice}
                                            />
                                        </div>
                                        <Input
                                            allowClear
                                            className="tags__searchBox"
                                            placeholder={t(
                                                'assetModeller.modelOverview.searchTagNamePlaceholder'
                                            )}
                                            suffix={
                                                <SearchIcon id="assetsGroupLeftSection__searchIconId" />
                                            }
                                            onChange={handleSearchInputChange}
                                        />
                                    </div>
                                    <OTTable
                                        setSelectedRowIds={setSelectedRowIds}
                                        selectedRowIds={selectedRowIds}
                                        data={filteredData}
                                    />
                                </>
                            )}
                            {activeSubTabKey === TagMappingSubTabs?.itTags && (
                                <>
                                    <Select placeholder="Select Application" />
                                    <Input
                                        className="tags__searchBox"
                                        placeholder="Search Tag Name"
                                        suffix={
                                            <SearchIcon id="assetsGroupLeftSection__searchIconId" />
                                        }
                                    />
                                    <ITTable />
                                </>
                            )}
                            {activeSubTabKey === TagMappingSubTabs?.forms && (
                                <>
                                    <Select placeholder="Select Form" />
                                    <Input
                                        className="tags__searchBox"
                                        placeholder="Search Tag Name"
                                        suffix={
                                            <SearchIcon id="assetsGroupLeftSection__searchIconId" />
                                        }
                                    />
                                    <FormsTable />
                                </>
                            )}
                        </Card>
                    </Col>
                    <Col span={16}>
                        <div>
                            <TagTable
                                setPage={setPage}
                                page={page}
                                activeSubTabKey={activeSubTabKey}
                                setSortColumn={setSortColumn}
                                setSortOrder={setSortOrder}
                            />
                        </div>
                        {mappedTagsList?.totalElements > PAGE_SIZE && (
                            <CustomPagination
                                totalRecords={mappedTagsList?.totalElements}
                                setPage={setPage}
                                page={page}
                                setPageSize={setPageSize}
                                pageSize={pageSize}
                                isPositionFixed={false}
                            />
                        )}
                    </Col>
                </Row>
            </div>
            {openConfirmationModal && (
                <ConfirmationModal
                    icon={<QuestionMarkIcon />}
                    open={openConfirmationModal}
                    onOk={() => {
                        dispatch(
                            mapTagNodes({
                                nodeId: selectedAsset?.key,
                                tagIdList: selectedRowIds,
                                deviceUuidList: selectedDeviceValue,
                            })
                        );
                        setOpenConfirmationModal(false);
                    }}
                    onCancel={() => {
                        setOpenConfirmationModal(false);
                    }}
                    text={t('assetModeller.modelOverview.mapTagsModalText')}
                />
            )}
        </>
    );
};
export default TagMappingSection;
